import React from 'react';
const About = () => {

    React.useEffect(()=>{

        generate_pattern()
    })

 return  <>
    <div id ="homepage" style={{width:"100%", height:"100vh"}}>
    <div id= "header" style={{position:"absolute", backgroundColor:"white", margin:"10rem", padding:"2rem"}}>
    <h1 id="title">
    Soft Synthetics
    </h1>
    <h4>           Formerly 49 Chakras</h4>
    <p>We contribute to the creation of music</p>

    <p>_____________</p>
    <p>We as in just me. I've made lots of DIY synthesizer stuff and feel the need to share it instead of stashing it away in shame</p>
    <p>Based in Glasgow, Scotland</p>
    </div>


    <canvas id="canvas" width="1000" height="1000" className="canvas"></canvas>
    {/* <button onClick={location.reload()}>generate pattern</button> */}
    </div>
</>
}

// window.onload = ()=>{
//     generate_pattern()
// }

function cycle(pattern = [1,2,3], direction = 1){
    let new_pattern = []
    let i = 0;
    while (i < pattern.length){
    let old_pattern_index = (direction + i)%pattern.length
    new_pattern[i] = pattern[old_pattern_index]
    i++;
    }
    return new_pattern
}

function invert(pattern){
    let new_pattern = []
    let i = 0;
    while (i < pattern.length){
    new_pattern[i] = !pattern[i]
    i++;
    }
    return new_pattern
}

function add_sides(pattern, number_of_sides){
'randomly add or a remove the given number of sides'
return pattern
}

function randomize_order(pattern){
 //TODO how to bag in JS
return pattern
}

function random_pattern(blank_ratio=0.5){
    let pattern=[]
    let i = 0;
    while(i<8){
        pattern.push(Math.random()<blank_ratio)
    i++;
    }
    return pattern
}


function generate_pattern(pattern = null, blank_ratio = 0.5, apply_on_x_only = null){
    let canvas = document.querySelector("#canvas")
    const ctx = canvas.getContext("2d")
    let contentContainerNode = document.querySelector('#homepage')
    canvas.height = contentContainerNode.offsetHeight;
    canvas.width = contentContainerNode.offsetWidth;
    ctx.beginPath();
    ctx.rect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle="white"
    ctx.fill()
    
    let rules = []
    let pattern_shuffles = [
        invert,
         (pattern)=>{return cycle(pattern, 1)},
          (pattern)=>{return cycle(pattern, 2)},
           (pattern)=>{return random_pattern(0.5)},
           (pattern)=>{return pattern}
        ]

    if( pattern == null){      
        pattern = random_pattern(blank_ratio)
    }
    console.log(pattern)
    if (apply_on_x_only == null){
        apply_on_x_only = Math.random < 0.5
    }
    //TODO choose a selection, with cyclical 
    let shufflle_func =  pattern_shuffles[Math.floor(Math.random()*pattern_shuffles.length)]
    let radius = 25
    let x = -radius
    while( x < canvas.width+radius){
    let y = -radius
    x+=radius*2
    if (apply_on_x_only){
        pattern = shufflle_func(pattern)
    }
    while(y < canvas.height+radius){
    y+=radius*2
    draw_hex(ctx,x,y,radius,pattern)
    pattern = shufflle_func(pattern)
    
    }
    }



}


function draw_hex(ctx, x, y, radius, sides = [1,1,1,1,1,1,1]){
//TODO: why only septagons?
ctx.beginPath();
ctx.moveTo(x-radius,y-(radius/2))


if(sides[0]){
ctx.lineTo(x-radius, y+(radius/2))
ctx.stroke()
}
else{
 ctx.moveTo(x-radius, y+(radius/2))
}
if(sides[1]){
ctx.lineTo(x-(radius/2), y+radius)
ctx.stroke()
}
else{
 ctx.moveTo(x-(radius/2), y+radius)

}
if (sides[2]){
 ctx.lineTo(x+radius/2, y+radius)
 ctx.stroke()
}
else{
 ctx.moveTo(x+radius/2, y+radius)
}

if(sides[3]){
ctx.lineTo(x+radius, y+radius/2)
ctx.stroke()
}
else{
 ctx.moveTo(x+radius, y+radius/2)

}
if (sides[4]){

ctx.lineTo(x+radius, y-radius/2)
ctx.stroke()
}
else{
 ctx.moveTo(x+radius, y-radius/2)

}
if (sides[5]){
 ctx.lineTo(x+radius/2, y-radius)
 ctx.stroke()

}
else{
 ctx.moveTo(x+radius/2, y-radius)
}

if(sides[6]){

 ctx.lineTo(x-radius/2, y-radius)
ctx.stroke()
}
else{
 ctx.moveTo(x-radius/2, y-radius)
}

if(sides[6]){
 ctx.lineTo(x-radius,y-(radius/2))
 ctx.stroke()
}
else{
 ctx.moveTo(x-radius,y-(radius/2))
}
}


export default About