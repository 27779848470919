import {Row,Col,Card, Container} from "react-bootstrap"
import {Link} from "react-router-dom"
import { pdfjs, Document, Page } from 'react-pdf';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const MULT = () =>{

 

  return <div style={{padding:"2%"}}> 
  <h1>MULT</h1>
  <p>MULT is a DIY framework for creating your own utility modules. At its core, MULT is your bog-standard multiple providing 2 chained 1 to 4 multiples.</p>

  <p>Howver, the circuit has some loose ends and optional components that allow you to change it's behaviour!</p>
  
  <p>Firstly, you have the option to exclude the powered section of the circuit to make it active or passive, ideal for beginners. <br></br>
    Secondly, The module also provides various unused pads to install your own components which will change the behaviour. Likewise, you could just swap out anything too!</p>

  <p>Without much effort it's easy to turn to make this module implement quite a few circuits that share the same general topology, to name a few:</p>
  <ul>
    <li>Multiple</li>
    <li>Rectifier</li>
    <li>Clipper</li>
    {/* <li>Inverter</li> */}
    <li>Fixed Filter Bank /slew limiter</li>
    <li>Active Fixed filters</li>
    <li>Voltage Mixer</li>
    {/* <li>Passive OR Gate</li> */}
  </ul>
  {/* <p>With more effort and creativity, you could create even more. For example:</p>
  <ul>
    <li>Voltage Controlled Clipper</li>
    <li>Passive Gate to Trigger</li>
  </ul> */}


  <p>But you can most certainly do even more with some more creativity.</p>
  <h2>Schematic</h2>
  <a href="/MULT/MULT schematic.pdf" download>Download PDF</a>
  <br></br>
  <h2>BOM</h2>
  <a href="/MULT/BOM.html">Interactive BOM</a>
  <br></br>
  <div style={{maxWidth:"100%", maxHeight:"70vh", overflow:"hidden"}}>
  {/* <Document file="/MULT/MULT schematic.pdf" onLoadSuccess={()=>{}} >
        <Page pageNumber={1} />
  </Document> */}

  {/* <p>It's your basic buffered multiple except it has some jumper points which you can either short or solder your own 0805 components!</p> */}
  </div>

  {/* <h2>Resources and Recipes</h2>

  <p>I have put together some example circuits you can create for inspiration.</p>

  <ul>
    <li><a href="https://modwiggler.com/forum/viewtopic.php?t=197092&sid=fa1dc8806092578b1b78ad03f19a7e40"></a>An excellent collection of passive circuits involving diodes</li>
  </ul> */}
  <br/>
  <h2>Recipes</h2>


  <Tabs>
    <TabList>
      <Tab>Active multiple</Tab>
      <Tab>A passive Hub</Tab>
      <Tab>Rectifier</Tab>
      <Tab>Clipper</Tab>
      <Tab>Passive Mixer</Tab>
      <Tab>Fixed Filter Bank</Tab>
      <Tab>Playground</Tab>
    </TabList>

    <TabPanel>
      <h4>Active multiple</h4>
      <p>By simply Installing every component and shorting the bridges from OPT1 through to OPT8 with some solder before each ouput jack you will build an active multiple. lovely! </p>
      <img src="/MULT/active mult.PNG" style={{ width:"100%"}}></img>

    </TabPanel>
    <TabPanel>
      <h4>A passive Hub</h4>
      <p>By shorting the header pins at Passive1 and Passive 2, the circuit will skip over the active section of the circuit rendering it passive.
        If all you need is a 5 way pssive hub, just don't bother soldering anything else apart from those output jumpers! </p>
      <img src="/MULT/Passive Hub.png" style={{ width:"100%"}}></img>
    </TabPanel>
    <TabPanel>
    <h4>Rectify</h4>
    <p>By utilising the provided jumper spots, we can start adding functionality to each of the outputs. </p>

    <p>By using a diode (1n4148 is the most common and cheapest you'll find), You can create an inprecise rectifier circuit which allows voltage to flow in only one direction at each output</p>

    <div style={{"width":"80%"}}>
    <iframe
    src="https://www.falstad.com/circuit/circuitjs.html?ctz=CQAgjCAMB0l3BWcMBMcUHYMGZIA4UA2ATmIxAUgpABZsKBTAWjDACgA3cQqvKsHuGIoo4WlSRUp0BGwDm3fsMUgUNKVDYAnENjw0QfXfqEj+8OGwDuxg2GUClZtgBNV6w1WxpPqkC4YAMwBDAFcAGwAXa3defkEjSBjvONtfJJoUemw6IV1ctWJNAA9dBCQEbOJ6BEJadxAAeVDogHs-QgMqdVJqGAsyQlqUST8RbDYgA"
    style={{width:"100%", height:"40vh"}}></iframe>
    </div>
    <img src="/MULT/basic rectify.PNG" style={{ width:"100%"}}></img>

    <p>Caveat: The attenutation of ther output from this rectifier is very depentant on the impedance of your input(s) so you should expect some signla loss!</p>
    <p>You can create a precise active rectifier by changing a couple components of the active output buffer. Note this will affect all 4 outputs of course.</p>
    <p><a href="https://www.allaboutcircuits.com/video-tutorials/op-amp-applications-active-rectifier/">Here</a> is a circuit that will have minimal voltage drop and droop. The topology is already there, Just replace C5/C6 and R1/R2 with the diodes</p>
    <iframe style={{width:"100%", height:"40vh"}}
     src = "https://www.falstad.com/circuit/circuitjs.html?ctz=CQAgjCAMB0l3BWc0EDYAsAmVBmMAOfdA-MAdkxDxCXRxoFMBaMMAKACUQX10R183TJj4Co-EJjg1xkKCjYAnbmF79BTMWLlh4cNgENuYjOpCnBYJCyS69cpjD2Z8ATnfF3rhCNeoi4nbwhuAuIPhyInIR4eDWVuD2ck7OqGhgrjiQmN6oaZmB9kpmpmBhpjpFAOahgn61DclsNZqCMa3gZbLFHUw4lGJ9lJV6bADuxhr9k2aQ4yWiU8PzZYLTq+Z8c8pgqA67kmgqqIWjE1FUlBcxc+dHLCcuGge34HvHDQ9QK2FfG9NzLD0HD+bj1EFkSSQLbFdCmL4ILpfEbBZRwvgxRGUG6JUY1LEfHzPE5NfFdTFhHGvJ7hOQ4EG074TeknMQsj6vOjYuToxmc+EHLkctgAEwa0wuEpAIoYADMDABXAA2ABd5jSvjSAerCJdJLqbqLJOhopEDZFpXLFaq2AAPKj4ehMBC0TAQZ0DY0gADyCrVzIZMXZ2nmQqDgaaAZOXyFyLYAHtwIJhvxIO4ZCk4K4yGlUJgkBaCFQ2EA">Falstad Circuit simulator Link</iframe>
      <img src="/MULT/active rectify.PNG" style={{ width:"100%"}}></img>

    </TabPanel>
    <TabPanel>
      <h4>Clamp</h4>

      <p>With a resistor and a couple zener diodes you could make a very simple clamping circuit on each output too!
        This will require you to solder the diodes in parrallel with the output - by adding them between the audio jack tip and sleeve pins.</p>
      <div style={{"width":"80%"}}>
      <iframe
      src="https://www.falstad.com/circuit/circuitjs.html?ctz=CQAgjCAMB0l3BWcMBMcUHYMGZIA4UA2ATmIxAWwpABYqEBTAWjDACgA3cQyEPXsD3DEUUcNwG8pUaAjYBzCcNGDeKGtMhsATiGx4afXvsNgRYsPDhsA7nrTKlZ0Vrvre-JZ9f2Pxg0ZQbDQoVNh0wnoR6sRBAB60mCAONIRIobTJhgDyAK4ALjpZfr6BktZ22A7OegE1WgBepZ5VAih4ySAAJgwAZgCGuQA2+UwNDAB2DNpsTa2O82DtnT0Dw6PjUzMJ4bGEHfoQGBCG6iAAKgCWAA5sOwgQKGAdIadPmWcAykMMDBwMbAA9p0XLRIKRkDIrGQ0oQUEg1J1sGwgA"
      style={{width:"100%", height:"40vh"}}></iframe>
      </div>
      <img src="/MULT/clip.PNG" style={{ width:"100%"}}></img>

      <p>This <a href = "https://www.electronics-tutorials.ws/diode/diode-clipping-circuits.html">tutorial on diodes</a> is a great introduction to this</p>
      </TabPanel>
    <TabPanel>
      <h4>Mix</h4>

      <p>By skipping over the active section of the circuit, soldering resitors across OPt1 through OPT8, and a resistor between the pins of Passive1 and passive you will create a very simple voltage mixer!</p>
      <p>In this setup, the outputs and inputs have been swapped. The ratio of inputs when mixing is set by the value of the resistors chosen, An input with relatively higher resistrance will contribute less to the output.</p>
      <iframe src = "https://www.falstad.com/circuit/circuitjs.html?ctz=CQAgjCAMB0l3BWcA2aYDMZIA5nrzgEzYCcIyh4ICk1ApgLRhgBQATuMSIQCy2-8+UcPDjtuQsAHZkIdAkrTZtLGI4CJteZQ0r447XMg9NRk-30B3U0tO6W1jU6HpjUFgCU5Cs3OwnXc24QPlokWgjoBE9vHRd-Uy0QbDDhSOivQ1t0BNsVENSIqCiWAHlObFMUxPcAD3BmbjdCQgspEIkQAAUAQwBnAYBLADc6VnrCEhMWiHlZFpNpk1KugBVCFgmSdrAabzJd9A7eEBXV8e4p7mx2ucvaJdO19BYAexAyZCC+EkqsYogKhYQA"
      style={{width:"100%", height:"40vh"}}></iframe>
      
      <a href = "https://en.wikipedia.org/wiki/Electronic_mixer">Electronic Mixer wikipedia page</a>
    </TabPanel>
    <TabPanel>
      <h4>Fixed Filter Bank</h4>
      <p>Just like with the diode clamps and rectifiers, you can use capacitors at OPT1 through 9 to create fixed high and low pass circuits at each output. 
      
      <iframe src="https://www.falstad.com/circuit/circuitjs.html?ctz=CQAgjCAMB0l3BWcA2aYDMZIA5nrzgEzYCcIyh4ICk1ApgLRhgBQATiIQCy1gDsyEOgSV+g3vHZCRQyF2mV82KOEkB3Tj3ADNtbrUgsN+3afRyoLAEqmT6Sib0getJAajQELAPILtgri5lMRVDAA9OBAgwLj4hDHAaZ00QbwAFABVWCMJkPWRlewc+CHluVMzCHz9zeUDCiwMWAGM-EOFKEnFwWHg+-rgwRjiGLmgSEi5kMEIaXNJ0Cbde1gBzPy6hQlpNpta7Cw7ZeV5egfOhhhGxiamZuYKSRfQPQZZ1o6Ut2i+miPQ+KJttRINFCC8yvIAILNNgAewAzgiADoIgBSAENmgBrFj-QHSZQ0CD4UopGHwpGozE4lhw6jJWg8EhEjwQXh0kDKE7OSAs1RsqisIA"
       style={{width:"100%", height:"40vh"}}></iframe>

      <a href = "https://www.electronics-tutorials.ws/filter/filter_2.html">This tutorial will explain how to select apprropriate values</a> </p>
      
      <p>If you wanted to actively low pass filter the entire unit, you could increace the capacitance of C5, C6, which are used to attenuate very high frequencies anyway.</p>
      

    </TabPanel>
    <TabPanel>
      <h4>Playground</h4>
      <a href = "https://www.falstad.com/circuit/circuitjs.html?ctz=CQAgjCAMB0l3BWEA2aAWB8CcBmSy0AOBBAdkJDVJCTRxoFMBaMMAKACUQW01KKmAJkF8iUSiEFwa4yFGgI2AJ25he-bmLFyw8OGwCGmigQ2mKYJCyS69cmHrA5SWLKRFwqghFjDUddobgghSEch4gYZHgVpbgdnJMDvBqYMjIpFROkB6u9AF6ymZ8YCEofAXwbADmwRRYyHVN9jXGkYlirIKyRUxiTDjd-YPitlUA7m0D3X0U2myTYqbD3ZALkoSh4ZuqjWuTpRQjh+VQRWmJaZIIjSx78YUAxk0jESPh0ILMrKroacibQguUikUqCBryODsSZvboRKL7a63K4hARXREXXZNO5nA5lHEnd5FeHbLajKpoQT0HAA7gQmnUKQVIpoUxyBClWQPKoqVl8KIc7oI7n6WqC8QIMr2M5izkCsrCxGo9qUK4I9Z0IVyTWnRE65bvXGUEZRPkqvVs42rI0601qloAe0kcktaEgrnikOwpGQN284jhchwbCdgggrvdWE9yTgbl9yH9EDhEGDQA">Full Screen Version</a>
      <div style={{"width":"80%"}}>
      <iframe
      src="https://www.falstad.com/circuit/circuitjs.html?ctz=CQAgjCAMB0l3BWEA2aAWB8CcBmSy0AOBBAdkJDVJCTRxoFMBaMMAKACUQW01KKmAJkF8iUSiEFwa4yFGgI2AJ25he-bmLFyw8OGwCGmigQ2mKYJCyS69cpjD15CWXISlhBOIjno67huCCFIRyInKhIBZWluB2co7wRMhgxHDIWJCEqXB8-nrKZnyeJnlxBQDmQRRYyNX1CWxVTGKRLRaesoXt3DiCGkx94rbwbADuxr39PdrjRQNDkHPBIWGEAmB1SxMlIEO7pksqm-abkgh1LFvlowDG9UPhe-060ITMrJRvKS6EyISkBDuNBYCCJdgTJ6PNARRqQi6qOorRFQOYnFG7K6onbBDG4xaFJ6RZGRfJLNBeGjSFiQUSucC1VFVC6wmikOqk7GSdZROQ4Qh8UlzfmCvkCroTNBnMVlbaUM6REW81EAE3l1zQh0kIBVDAAZgYAK4AGwALmw1ZrRerlf1dQaTeaVGRrghOgkbvpnchWW7+pyRvpJeY5H7lXKpXU1HwldGubH1EqhRMEzHxcI8sL0yIbRnUSofRZ1CzwOoyfoqoXS3xkDm43DKKY42H62x7lbqzb65JoIJmNQcNAcFhBOQvG7kORQkhwYUO3H5yPhqMqov+nR+mAlw2W8WfZ2jrmcx282S242+HmT6QXvI7BAWNBdKRMqQspPQaEcDPYJB2KvTFHdcziAiUaE6PMS1PJoUCySQc1rS8c0aAs4Mg-dT09OUwyvQDkLmDtQ3dJk2VdbcugAez2GsyhheldDvbB2QuQQZ21bw9jYKjNRQWjIHpH89CwZjaxsbUeJwNggA"
      style={{width:"100%", height:"40vh"}}></iframe>
      </div>
    </TabPanel>
  </Tabs>


  
  {/* <h3> More Ideas</h3>
  <a href="https://circuitdigest.com/electronic-circuits/half-wave-and-full-wave-precision-rectifier-circuit-using-op-amp">With some creative soldering you could create active precise half and full wave rectifiers</a> */}


  </div>
}
export default MULT