import { Col, Row } from "react-bootstrap"

const CAVBuild = () =>{
    
    return <> <h1>How to build CAV</h1>
      
    <p>CAV comes SMD pre-soldered. If you have a DIY version you will need to finish the build by soldering the remaining through hole parts:</p>
    <p>The <a href = "/#CAV/ibom.html" target="_parent">BOM</a> describes the solder-able components you will need.</p>
    <p>On top of this, you will also require 3 2.54mm jumpers to select the modules operation modes
        (you can make these yourself pretty easily, or just skip this whole step and permanently solder the points you want together)</p>

    <p>The full list of required components and reasoning is here:</p>
    <table>
        <tr>
        <th>Name  </th>
        <th>Quantity  </th>
        <th>Mandatory</th>
        <th>Source </th>
        <th>Details</th>
        </tr>
        <tr>
          <td>Mono 3.5mm Jacks (thonkiconn)</td>
          <td>7</td>
          <td>Yes</td>
          <td>
          <a href="https://www.taydaelectronics.com/pj-3001f-3-5-mm-mono-phone-jack.html">Tayda: PJ-3001F</a><br/>
          <a href="https://www.thonk.co.uk/shop/thonkiconn/">Thonk: Thonkiconn PJ398SM</a>
          </td>
          <td>The ones just about everyone uses</td>
        </tr>
        <tr>
          <td>Shrouded 2x5 pin header</td>
          <td>1</td>
          <td>Yes</td>
          <td>
            <a href = "https://www.thonk.co.uk/shop/eurorack-diy-essentials/">Thonk</a><br/>
            <a href = "https://www.taydaelectronics.com/10-pin-box-header-connector-2-54mm.html">Tayda</a>
          </td>
          <td>Can use unshrouded pins if you live fast and loose, the module has input protection</td>
        </tr>
        <tr>
          <td>PTL45: 45mm LED slider</td>
          <td>2</td>
          <td>Yes</td>
          <td>
            <a href = "https://www.digikey.co.uk/en/products/detail/bourns-inc/PTL45-15O0-104A3/3781310">Digikey</a><br/>
            <a href = "https://www.mouser.co.uk/ProductDetail/Bourns/PTL45-15R0-103B2?qs=XxajcJZMfNwybdu%2Fz5bMWA%3D%3D">Mouser</a>
          </td>
          <td>
          linear taper, any resistance will do (I use 10k Ohms)
          </td>
        </tr>
        <tr>
          <td>M2 Screws</td>
          <td>4</td>
          <td>Yes</td>
          <td></td>
          <td>For binding sliders to front panel. The little kits you can get on eBay will have something that works</td>
        </tr>
        <tr>          
          <td>M2 spacers</td>
          <td>4</td>
          <td>No</td>
          <td></td>
          <td>For binding sliders to front panel properly</td>
        </tr>
        <tr>
          <td>2.54mm box pin headers</td>
          <td>11</td>
          <td>No</td>
          <td>
            <a href = "https://www.thonk.co.uk/shop/eurorack-diy-essentials/">Thonk</a> <br/>
            <a href = "https://www.taydaelectronics.com/12-pin-2-54-mm-single-row-pin-header-strip.html">Tayda</a>
          </td>
          <td>The headers are used to select behaviour, not needed if you want to hard-set the options</td>
        </tr>
        <tr>
        <td>1x2 2.54mm header jumpers </td>
        <td>3</td>
        <td>No</td>
        <td>  <a href = "https://www.taydaelectronics.com/mini-jumper-2-54mm-gold-plated-closed-cover.html">Tayda</a></td>
              
        <td>Shorts headers to select behaviour of unit. Can be made DIY with some creativity, not needed if you want to hard-set the option</td>
        </tr>
        <tr>
          <td>Ferrite bead          </td>
          <td>2</td>
          <td>No</td>
          <td>
          <a href = "https://www.taydaelectronics.com/axial-ferrite-bead-76-ohm.html">Tayda</a>
          </td>
          <td>  provides noise protection. Can use a 0 ohm resistor or wire instead
          </td>
        </tr>
      </table>
      <br></br>
    <h2>Lets Go!</h2>
      <p>There aren't many steps, promise!</p>
      <Col>
      <Row style={{}}>
    <Col style={{minWidth:"40rem"}}>
    <h4>Step 1:</h4>
    <p>Solder the 2x5 shrouded power header, P1, and configuration header pins JOuts1, JNorm1, JNorm2, JNormExt </p>
    </Col> 
      <Col>
        <img src="/CAVstep1.jpg" style={{height:"40vh"}}></img>
        <p>Solder your headers, it's tricky to make them stand up straight but they don't need to be!</p>
      </Col>
      <Col>
        <img src = "/CAVstep2.jpg" style={{height:"40vh"}}></img>
        <p>Solder Power Header.<br></br> white line indicates -ve rail (red stripe on your power connector)</p>

      </Col>
    </Row>
    <Row style={{margin:"4rem"}}></Row>
    <Row>

    <Col style={{minWidth:"40rem"}}>
    <h4>Step 2:</h4>
    <p>Solder The ferrite beads, FB1 and FB2, to the front of the PCB.
       You absolutely can get away with just using a wire or 0 ohm ressitor here if obtaining these beads is hard.</p>
    </Col>
    <Col className="col-6">
          <img src="/CAVstep3.jpg" style={{height:"40vh"}}></img>
          <p>Solder ferrite beads</p>

      </Col>

    </Row> 
    <Row style={{margin:"4rem"}}></Row>
    <Row>
    <Col style={{minWidth:"40rem"}}>
    <h4>Step 3:</h4>
      <p>Solder the sliders and jacks to the front of the PCB. You can use the front panel to bind the components in place.
        I recommend you solder the jacks first, ensuring the PCB and front panel are parallel to each other. </p>
      <p>The the sliders can be directly screwed to the front panel, or you can use some spacers between the panel and component.
         In this example I have screwed them directly to the front panel resulting in a taller lever.
          The pins do not go all the way through the their holes but supplying enough solder on both sides will do the job. Please use spacers if this offends you.</p> 
          </Col>
      <Col className="col-3">
      <img src="/CAVstep4.jpg" style={{height:"40vh"}}></img>
      <p>Slider attached to front panel</p>

      </Col>
      <Col className="col-3">
      <img src="/CAVstep4-2.jpg"style={{height:"40vh"}}></img>
      <p>Excessive solder to attach slider legs</p>

      </Col>
    </Row>
    <Row style={{margin:"4rem"}}></Row>

    </Col>

    <Row>
      <h4>Step 4: Jumpers</h4>
      <p>The modules features some jumpers that allow you to configure it's behaviour. These are described in 'Operation Modes'.</p>
      <p>A short description of each header and the standard setup is as follows.</p>
      <ul>
        <li><b>Norm</b> : Send the 5V reference, your own signal, or nothing to the attenuvert-ing input of unit 1. <br/> - connect 5V and Ext</li>
        <li><b>JNorm2</b> : 1st phase of choosing signal to normal to attenuvert input of unit 2, Select attenuverting or attenuating input of unit 1<br/> - Connect Vert to the middle</li>
        <li><b>SWNorm1</b> : 2nd phase of choosing signal to normal. Select the previous choice or the output of unit 1, or your own signal<br/> - Connect the 'in' arrow to the middle</li>
      </ul>
      <p>This setup normals the voltage reference to the top input of unit 1, which in turn normals it's signal to the top input of 2. I think it's the most flexible for everyday use but specif use cases might benefit from another setup</p>

      <img src="/headers_marked.png" style={{width:"80vw"}}></img>
      <p>The red circles indicate where I recomend you place jumpers</p>
    </Row>

  <h4>Step 5: Test</h4>
      <p>Double check everything then use your multimeter to make sure there are no shorts between either power rails and ground. Other than that, Let's plug it in and see what happens.</p>

    <h4>Step 6: Power on</h4>
    <p>if not using a shrouded header, plug your 2x5 power cable into the module, aligning the red line (indiciting -ve rail) with the line on the back of the PCB </p>
    <p> You will see the sliders light up as you move the slider position from the centre to top. Congratulations, you are done.</p>

  <Row style={{margin:"4rem"}}></Row>


    <h2>Operation Modes</h2>

    <h3>Ext/5v</h3>
    <p>The 'Ext' header decides which signal is normalised the primary input of the first unit. next to it is a the output of the voltage reference (5 Volts by default).
      By leaving the Ext input floating, the unit will have no default inputs. N.B. Version 3.3 was printed with an error that resulted in a voltage of 2.5V 
      By placing a jumper/shorting the Ext and 5V pins, the voltage reference will normal to the primary input.
      Otherwise, you can connect whatever you want to the Ext input!
    </p>

    
    <h3> Normalised input to the 2nd unit </h3>
      <p>Like the first unit, you may select which signal is normalized to the vert input of the 2nd unit. This is done in 2 stages.
      </p>

          <h4>A) Attenuverting or attenuating inputs of first unit</h4>

    <p>This header decides which input of the 1st unit is sent to the 2nd. Use a pin jumper to connect the 1st(vert) or 2nd(ate) input to the vert input of the 2nd unit (middle pin).
      normaling the vert input typically provides the most benefits and is the standard configuration.
    </p>

    <h4>B) Result of A or output of 1st unit </h4>
     <p> This header decides whether you want to send the selected signal of A or the output of the 1st unit (AXB) to the 2nd units input.

      The default configuration is to send the vert input from header A as this is the most versatile. 
  
    </p>  
      
      <br></br>
    <h2>
      Other points
    </h2>
    <p>The PCB includes headers on the back which expose each of the modules outputs.
       You can chain modules together by connecting one of a modules outputs to the Ext input of another</p>
       <p>Likewise, you can normal any signal to unit 2 through the middle pin of JNorm 1</p>
      <br></br>
    <h2>Hacks</h2>
    
    <h3>Change response curve of slider</h3>
    <p>A linear tapered slider potentiometer works well enough with CV and audio signals.
       If you prefer to use only audio signals then it's absolutely possible to solder some resistors across the potentiometers left and right legs to the wiper pins to create pseudo audio tapers. 
      <a href = "https://www.amplifiedparts.com/tech-articles/potentiometer-taper-modifications">This link</a> provides some great details on how to do that.
       Just be warned you will introduce some very minor attenuation. </p> 

    <h3>Change voltage output from regulator</h3>
    <p>In this revision of CAV, the voltage reference is delivered by a tl431 chip. The voltage output by the unit is dictated by:
      <br/>
          Vout = 2.5V * (1 + Rref1/Rref2)
      <br/>
      Rref1 and Rref2 are both 10k ohms to deliver 5 Volts, by removing or changing these resistors you achieve voltages between 2.5V and 12V (+ve rail voltage). Again, this doesn't work on V3.3!
    </p>
    <h3>brightness of LED</h3>
    <p>CAV expects voltages between 0 and 12 volts, typically 5V. A 680 ohm resistor is used to limit the current flowing through the LED, ensuring a full 12V signal will not result in too much current.
      If you feel comfortable or have some LEDs which can handle more current it's very easy to replace these LEDs for a brighter or dimmer LED
    </p>
    <h3>bi-colour LEDs</h3>
    <p>The LEDs of the PTL slider are very easily replaceable by just pulling them out, you can replace them with any colour you like, including a bi-colour LED if you can find one.</p>
</>
}

export default CAVBuild