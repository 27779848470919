// import "./DSP.css"
import {Row,Col,Card, Container} from "react-bootstrap"
import {Link} from "react-router-dom"
const DSP = () =>{
    return <div style={{padding:"2%"}}>   
    <h1>DSP</h1>
    <h3>A Eurorack module using a cheap Karaoke module with 100 kinds of effects</h3>
    
    <p>This is a DIY project for using <a href ="public/DSP/DSP board.jpg">a cheap karaoke DSP unit</a> to create a decent
     stereo effects processor eurorack module... with some (bizzare) votlage control and a feedback circuit!

     The DSP unit brings 100 DSP algorithms:
    </p>
    
    <ul className="flex-container">
    <li className = "flex-item" style={{width:"30rem"}}>
        
    <ul>
        <li>00~02 small hall</li>
        <li>03~05 medium hall</li>
        <li>06~08 large hall</li>
        <li>09 church</li>
        <li>10~12 small room</li>
        <li>13~15 medium room</li>
        <li>16~18 large room</li>
        <li>19 hall</li>
        <li>20~26 with metal acoustic reverb</li>
        <li>27~29 Spring reverberation</li>
        <li>30~35 Reverberation door</li>
        <li>36~39 Reverse reverb</li>
        <li>40~43 Early reflection</li>
        <li>44~47 Atmosphere effect</li>
        <li>48 stadium</li>
        <li>49 Ambient effect FX</li>
        <li>50~58 Delay</li>
        <li>59 echo</li>
        <li>60~65 chorus</li>
        <li>66~69 Flange</li>
        <li>70~73 Phase shift</li>
        <li>74~79 Tone</li>
        <li>80-81 Reverb+chorus</li>
        <li>82~83 Reverb+chrome</li>
        <li>84~85 Reverb+phase shift</li>
        <li>86~87 Reverb+tone</li>
        <li>88~89 Delay+reverb</li>
        <li>90 Delay+reverberation gate</li>
        <li>91 Delay+reverse reverb</li>
        <li>92~93 Delay+chorus</li>
        <li>94~95 Delay+chrome</li>
        <li>96~97 Delay+phase shift</li>
        <li>98~99 Delay+transposition</li>
        </ul>
    </li>
    <li className = "flex-item" style={{width:"30rem"}}>
        <img src={"/DSP/DSP on.jpg"} style={{"width":"100%"}}></img>
    </li>
    </ul>

    <h2>Features</h2>
        <p>A feedback circuit with manual mixing and direct wet singal in.</p>
        <p>A manual wet/Dry mix</p>
        <p>Mono input, stereo output</p>
        <p>Voltage controllable clock modulation</p>

    <h2>Parts</h2>
    <p>The core of this module is one of these DSP units you can find on eBay, Aliexpress, or equivelant. 
        They audio processor on the board itself is actually pretty good, providing stereo 24bit 192kHz signal out from a single mono input.</p>
    <p>The rest of the design needs some easy to find and typically used through hole components including the ever available TL074, and a PT2399 chips.</p>

    <p>The <a href ="https://github.com/Owen-Patrick/SoftSynthetics-DSP">Github</a> includes a handy ibom which describes the components and placements.</p>

    <h2>Caveats</h2>
    <p>The clock modulation will require removing the DSPs crystal.  </p>
    <p>v1.0 has been built and tested but clearance issues and backwards potentiometers makes for a sight for sore eyes.
         V1.1 addresses these but is untested. There are no changes to the schematic itself so I'd trust it, but you should double check if you don't.</p>

    <h2>Acknowledgments</h2>

    <p>A few designer have already had a go at this before; but this is one is mine. It takes heavy inspiration from them and includes the unique features they each introduced and explored</p>

    <ul>
        <li><a href="https://modularforthemasses.square.site/">Modular for the Masses (Junito Moore)</a> - <a href="https://drive.google.com/file/d/1UECoI_JlwZcMkhOlTTOBQnz6PQ_TMQOb/view">"My Favourite Reverb"</a>
        <ul><li>I adopted the wonderfully creative clock control using a PT2399 chip.</li></ul>
        </li>
            <li>Jos from the LMNC forums - <a href="https://lookmumnocomputer.discourse.group/t/reverb-o-matic-100-fxs-reverbs/1856/3?page=2">Reverb-o-matic</a>
            <ul></ul>I took inspiration from single gang pot Wet/Dry mixer used in this scehmatic</li>

            <li><a href = "https://note.com/solder_state/n/nd9c984a9ab7a"> Hagiwo - 2500円で作るDSPマルチエフェクター-モジュラーシンセ自作  </a>
            <li>Hagiwo is a powerhouse in making lovely innovative and accessable DIY modules. They put me onto this device in the first place</li>

            <li>And more!</li>
        </li>
    </ul>

    <p>I have included a link to the kicad project with the hopes that you might develop it further.</p>
    <h2>Construction</h2>
    <p>To fit the feature set and in this form factor, the board layout is a little bit unorthodox but there isn't much room to make a mistake.</p>
    <ul>
        <li>
            The display board attaches to the back of the main back board of via 2 M3 stadnoffs. THe 2 robbon cables will need to wrap around the boards to reach the DSP circuit board.
            The Main board connects to the interface pcb with an M3 standoff and a 2 row 2.54mm pin headers. 
            The DSP processor board mounts to the back of the interface with some M3 standoffs.

            The open pin headers connect the main board to the DSP board. 
                <ul>
                    <li>The DSP control board connects to the main board via the 2 provided ribbon cables</li>
                    <li>x goes to the clock in where you desoldered the crystal.</li>
                    <li>5V and ground go to the DSPs power inputs</li>
                    <li>in, Left out, and right out go the DSPs remaining open headers</li>
                </ul>
            The cables fit together that way you'd expect: x to the clock in, in, Lout, Rout to the DSP PCB, and 5v to power in of the DSP. 
        </li>
    </ul>

    <h2>Schematics and Gerbers</h2>
    <p>The Kicad project files are all hosted on github and you are welcome to do with whatever you want with it!</p>
    <a href = "https://github.com/Owen-Patrick/SoftSynthetics-DSP">Link to github</a>


    <h2>Licensing</h2>
    <p>CC BY-SA 4.0<br></br>
        which <a href = "https://creativecommons.org/licenses/by-sa/4.0/deed.en">means</a>. AKA Do what you want but be nice :)
    </p>
    
    </div>
}

export default DSP