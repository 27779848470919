import React, { useState, useEffect } from "react";

let colour ="white"
let canvas = undefined
let context = undefined
let peaks = 0
let waves = []
let band_size = 64;
let samples = 0;

const frequency_step = 24000/(band_size/2)

function Landing(){
    const [overlay_display, set_overlay_display] = useState("fadeIn")
    const ref = React.useRef()

    useEffect(() => {
      if (ref.current!=null) {
        context =ref.current.getContext('2d');
        canvas = context.canvas
        let contentContainerNode = document.getElementById('canvas_container')
        canvas.height = contentContainerNode.offsetHeight;
        canvas.width = contentContainerNode.offsetWidth;
            // do something here with the canvas
        samples = canvas.width;
            [waves,peaks] = create_waves()
            draw(context,waves)
            if (do_fft){
                connectAudioAPI()
            }
            animate()
            // setInterval()
      }
    //   ref.current=null
    }, [])

    useEffect(() => {
        let timeout = 0;

        function display(){
            clearTimeout(timeout);
            set_overlay_display("fadeIn")

            timeout = setTimeout(() => {
                set_overlay_display("fadeOut")
            }, 20000);
        }
        ref.current.addEventListener('mousemove', display);
        return () => {
            if (ref.current!=null){
                ref.current.removeEventListener('mousemove', display);
            }
          };
    },[])
    // let timeout;
    // let whenMouseMoves = () => {
    //     clearTimeout(timeout);
    //     timeout = setTimeout(()=>set_overlay_display({display:"none"}), 50);
    // }
    
    return (<>

    <div id="canvas_container" style={{height:"100vh", width:"100%", margin: 0, padding: 0}}>
                <div id="titleOverlay" className={overlay_display}>
                    {/* {<h1>Soft Synthetics</h1>} */}
                    {<h3>We Help Make Music</h3>}
                    {/* {<h3><a href="/home">Enter</a></h3>} */}
                    {<h3>_________________</h3>}
                    <h3 style={{display:"inline-block"}}>spectralise: <input type="checkbox" onClick={set_spectro}></input></h3>
                    <h3 style={{display:"inline-block"}}>Animate:<input type ="checkbox" onClick={set_ambient} defaultChecked></input></h3>
                    <h3><button onClick={randomize}>Randomize</button></h3>

                </div>

                <canvas id ="canvas" width={2000} height={1000} ref={ref}>test</canvas>
            </div>
            </>
    )
}


let new_leader = false
let current_leader = undefined

function draw_wave(context,
     frequency, offset, magnitude,phase, colour, offscene_callback = ()=>{}){
    context.moveTo(0,context.canvas.height)
    context.beginPath();
    context.lineTo(0,context.canvas.height)
    let i = 0
    let on_screen = false
    let samples= context.canvas.width
    while (i < samples){
        let ange_deg = ((i+phase) * frequency)
        let angle = ((Math.PI * 2 ) / samples) * ange_deg

        let y = (Math.sin(angle) * magnitude ) + offset
        context.lineTo(i,y)
        if(!on_screen){
            on_screen = y > 0
        }

        i+=1
        
    }

    // complete custom shape
    context.lineTo(samples,context.canvas.height)

    context.closePath();
    //   context.lineWidth = 5;
    context.fillStyle = colour;
    context.fill();
    if (!on_screen){
        //callback
        // offscene_callback(context.height)
    }   
}

function draw(context, waves){

    let num_waves = waves.length
    for(let wave_i in waves){
    let wave = waves[wave_i]
    //if a wave is now completely off scene, add in a new one for eternal patterns and nausia
    draw_wave(context, wave.frequency
    ,wave.offset,
    wave.magnitude,
    wave.phase,
    wave.colour,
    ()=>{
        if (current_leader == undefined){
            current_leader = wave_i
        }
        else if (!(current_leader == wave_i)){
            new_leader = wave_i
        }
        // colour = wave[wave_i].colour
    }
    
    );
    
    }
    if (new_leader != false){
    colour = waves[new_leader].colour
    
    let new_leader_wave = waves[new_leader]
    waves.splice(0,new_leader)
    new_leader = 0
    // let new_waves = [new_leader_wave] 
    let x = 0 
    
    while(x < (num_waves - waves.length+1)){
        waves.push(create_wave(context.canvas.height+(context.canvas.height/2)))
    
        x+=1
    }
    current_leader = new_leader
    new_leader=false
    }
    return waves
    }



//What if offset was always moving down, bringing in new waves as they disappear?
function calculate_next_step(waves){
    for(let wave_i in waves){
    let wave = waves[wave_i]
    for(let component in wave.animated_components){
        let component_info =  wave.animated_components[component]
        wave.animated_components[component].value += component_info.delta
        let delta_value = component_info.shape(wave.animated_components[component].value) * component_info.magnitude
        let prev_delta = 0
        if ("previous_delta" in component_info){
            prev_delta = component_info.previous_delta
        }

        component_info.previous_delta = delta_value
    
        waves[wave_i][component] += delta_value-prev_delta
    
    }
    }
    return waves
    }

function animate_step(context, waves, perform_animation=false)
    {
        if (perform_animation){
            waves = calculate_next_step(waves)
        }
        context.beginPath();
        context.rect(0, 0, context.canvas.width, context.canvas.height);
        context.fillStyle="white"
        context.fill()
        waves = draw(context, waves)
        return waves
    
}

//waves are defined by frequency, phase, magnitude, and height
  

    // let peaks = Math.floor(Math.random() * 6) + 3 

    // let num_waves = 4

    function create_wave(offset = 0){
        let frequency = 0.2 + Math.random() * 15 
        // frequency = 10
        offset = offset + Math.abs(Math.random()*200)
        let magnitude = Math.random() * 100
        let phase = Math.random()*180
        let colour =  "#" +Math.floor(Math.random()*16777215).toString(16);
        let animated_components = {
            phase:{shape:value=>value, value:0, start_value:phase, delta:0.1*Math.random()/2, magnitude:1},
            frequency:{shape:Math.sin, value:0, start_value:frequency, delta:Math.random()*0.0003*2*Math.PI/2, magnitude:Math.random()*4},
            magnitude:{shape:Math.sin, value:0, start_value:magnitude, delta:Math.random()*0.0003*2*Math.PI/2, magnitude:Math.random()*20},
            offset:{
                shape:(value)=>{
                    return Math.sin(value * 2*Math.PI)-Math.abs(value) - 0.1
                    // return -value
                }, value:0, start_value:offset, delta:0.003 * Math.random(), magnitude:(Math.random()*10)
            },
        }
        
        return {frequency:frequency,offset:offset, magnitude:magnitude, phase:phase,
        colour:colour, animated_components:animated_components}
    }

    // let wave_i = 0
    // let offset = - canvas.height / peaks /3
    // waves = []
    // let init_drawing_offset = offset
    // while(wave_i < peaks){
    //     waves[wave_i] = create_wave(init_drawing_offset + canvas.height / peaks-1)
    //     init_drawing_offset = waves[wave_i].offset
    //     wave_i+=1
    // }

    function create_waves(min_distance = undefined){
        if (min_distance == undefined){
            min_distance = canvas.height/6
        }
        let wave_i = 0
        let waves = []
        let offset = -canvas.height / 6 /3 
        let init_drawing_offset = offset

        while(init_drawing_offset < canvas.height){
            waves[wave_i] = create_wave(init_drawing_offset + min_distance)
            init_drawing_offset = waves[wave_i].offset
            wave_i+=1

        }
        peaks=wave_i
        return [waves,peaks]
    }


    
    // 
    // draw_wave(2,offset*2,magnitude*3,0,"red");

    // draw_wave(3,offset,magnitude,0,colour);

let analyser
let mediaSource
let imageDatalet
let audio_context

function getUserMedia(
    dictionary,
    callback
) {
    try {
        navigator.getUserMedia =
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia
        navigator.getUserMedia(dictionary, callback, (e) => {
            console.dir(e)
        })
    } catch (e) {
        alert('getUserMedia threw exception :' + e)
    }
}

function connectAudioAPI() {
    try {
        audio_context = new AudioContext()
        analyser = audio_context.createAnalyser()
        analyser.fftSize = band_size
        analyser.smoothing=10

        navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then(function (stream) {
                mediaSource = audio_context.createMediaStreamSource(stream)
                mediaSource.connect(analyser)
                animate()
                audio_context.resume()
            })
            .catch(function (err) {
                alert(err)
            })
        } catch (e) {
            alert(e)
        }

        // analyser.add()
}

function updateFFT(waves) {
    let timeData = new Uint8Array(analyser.frequencyBinCount)
    analyser.getByteFrequencyData(timeData)

    let bands = new Array(timeData.length);
    for (var i = 0; i < timeData.length; ++i){
        bands[i] = i;
    } 
    bands = bands.sort(function (a, b) { return timeData[a] > timeData[b] ? -1 : timeData[a] < timeData[b] ? 1 : a < b ? -1 : 1 });

    bands = bands.slice(0,peaks)
    bands.reverse()

    let maximum_freq_delta = 0.001
    let maximum_mag_delta = 0.03
    for(let i in waves){
            let j = waves.length-1-i
            let delta_frequency = (bands[i]*frequency_step /40 - waves[j].frequency) * maximum_freq_delta
            // delta_mag = ( timeData[bands[i]]/(0.01*(canvas.height/peaks)) - waves[i].magnitude) * maximum_mag_delta
            let delta_mag = ( (timeData[bands[i]]/512 ) * (canvas.height/(peaks-1))- waves[j].magnitude) * maximum_mag_delta
            waves[j].frequency += delta_frequency
            waves[j].magnitude += delta_mag
    }

    // let data = []
    // for (time in timeData){
    //     data.append({frequency:frequency_step*time})
    // }

    // for (let x = 0; x < 1024; x++) {
    //     context.beginPath();
    //     context.rect(x, 100,x+1,100);
    //     context.fillStyle = 'rgb(' + timeData[x] + ', 0, 0) '
    //     context.rect(x, 510, 2, 2)
    //     context.fill()updateFFT
    // }
    // console.log(JSON.stringify(waves))
    return waves
}
let do_fft = false
let do_animation = true

function animate() {
    requestAnimationFrame(animate)
    if (do_fft){
        waves = updateFFT(waves)
    }
    animate_step(context,waves,do_animation)
}

function set_spectro (){
    do_fft = !do_fft
    if (do_fft){
        connectAudioAPI()
    }
    else{
        // analyser.getTracks().forEach(function(track) { 
        // track.stop(); 
        // }); 
    }
}

function set_ambient(){
    do_animation = !do_animation
}

function randomize(){
    // function create_random_wave(offset){
    //     frequency = 0.2 + Math.random() * 15 
    //     // frequency = 10
    //     offset = offset 
    //     magnitude = Math.random() * 100
    //     phase = Math.random()*180
    //     let colour =  "#" +Math.floor(Math.random()*16777215).toString(16);
        
    //     animated_components = {
    //         phase:{shape:value=>value, value:0, start_value:phase, delta:Math.random()*0.001, magnitude:1},
    //         frequency:{shape:Math.sin, value:0, start_value:frequency, delta:Math.random()*0.0003*2*Math.PI, magnitude:Math.random()*5},
    //         magnitude:{shape:Math.sin, value:0, start_value:magnitude, delta:Math.random()*0.0003*2*Math.PI, magnitude:Math.random()*100},
    //         offset:{
    //             shape:(value)=>{
    //                 return Math.sin(value * 2*Math.PI)-Math.abs(value) - 0.1
    //                 // return -value
    //             }, value:0, start_value:offset, delta:0.003 * Math.random(), magnitude:(Math.random()*100)
    //         },
    //         // magnitude:{shape:Math.sin, value:0, start_value:frequency, delta:Math.random()*0.001*2*Math.PI, magnitude:Math.random()*100}

    //         // phase:{shape:Math.sin, value:0, start_value:phase, delta:Math.random()*0.01, magnitude:math.random()*100}
    //         // phase:{shape:Math.sin, value:0, start_value:phase, delta:Math.random()*0.01, magnitude:math.random()*100}

    //     }
        
    //     return {frequency:frequency,offset:offset, magnitude:magnitude, phase:phase,
    //     colour:colour, animated_components:animated_components}
    // }
    current_leader = undefined
    new_leader = false
    let random_number = Math.random()
    if (random_number >0.9){
        waves = []
        let i = 0
        while(i <15){
            waves[i] = create_wave(canvas.height/2)
            waves[i].animated_components.magnitude.magnitude *= 5
            waves[i].animated_components.magnitude.delta *= 20

            waves[i].animated_components.frequency.magnitude = 0
            waves[i].animated_components.frequency.delta = 0
            // waves[i].animated_components.phase.delta *= 

            i+=1
        }
        waves[14].animated_components.magnitude.magnitude /= 20
        waves[14].animated_components.magnitude.delta /= 10

        waves[14].animated_components.frequency.magnitude /= 10
        waves[14].animated_components.frequency.delta /= 10

        waves[14].magnitude = 10
        waves[14].frequency = 2
        waves[14].offset += 300

        peaks=14

        return

    }
    else if (random_number > 0.7){
        peaks = Math.floor((Math.random()*10))+20
    }
    else{
        peaks = Math.floor((Math.random()*8))+2

    }
    [waves,peaks] = create_waves(canvas.height/peaks)
    console.log(waves)
    return waves



}

export default Landing