import './sidebar.css';
import * as React from "react";

import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

const SideBar = ()=>{
    // return <Sidebar style={{width:"100%", minWidth:"100%"}} defaultCollapsed={true}>
    // <Menu>
    //     <MenuItem component ={<Link to="/" />}><h2>Soft Synthetics</h2> </MenuItem>
    //     <MenuItem  component={<Link to="/about" />}>About</MenuItem>
    //     <MenuItem> Blog</MenuItem>

    //     <SubMenu label="Products">
    //     <MenuItem href='/CAV'> about </MenuItem>
    //     <MenuItem href='/MULT'> about </MenuItem>

    //     </SubMenu >
    //     <SubMenu label = "Projects">
    //     <MenuItem> DSP </MenuItem>
    //     <MenuItem> Dump </MenuItem>
    //     </SubMenu>
    // </Menu>
    // </Sidebar>;
    return <div className="sidebar" id="sidebar">
        <div className="section">
        <Link to="/">
                <h2>Soft Synthetics</h2>
                <h5>Formerly 49 Chakras</h5>
        </Link>
        </div>
    <div className="section">
    <Link to = "/about">About</Link>

    <a href="https://www.instagram.com/soft_synthetics/">Instagram</a>
    </div>
    <div className = "section">
    <h3>Products</h3>
        <Link to = "/CAV">CAV</Link>
        {/* <Link to = "/CAV/build" className = "subPath">Build Guide</Link> */}
        <Link to="/MULT">MULT</Link>
        
    </div>
    <div className ="section">
    <h3>Projects</h3>
            <Link to ="/DSP">DSP</Link>
            {/* <Link to ="/CASE">MODULAR FRAME</Link> */}

    </div>

  </div>
}

export default SideBar