import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route,Navigate, Outlet,Link} from "react-router-dom";
import * as ReactDOM from 'react-dom';
import React from 'react';
import Landing from './pages/landing';
import CAV from './pages/CAV/CAV';
import CAVBuild from './pages/CAV/build';
import DSP from './pages/DSP/DSP';

import MULT from './pages/MULT/MULT';

import About from "./pages/about"
import SideBar from './components/sidebar';
import {Row, Col, Button} from "react-bootstrap"


function App() {
  const handle_sidebar = ()=>{
    // Announce the new orientation number
    let portrait = (window.innerHeight > window.innerWidth)
    if (portrait){
      collapse()
    }
    else{
      uncollapse()
    }
  }
  
  // window.addEventListener("resize", function() {
  //   handle_sidebar()
  // }, false);
  // window.addEventListener("load", function() {
  //   handle_sidebar()
  // }, false);

  const collapse = ()=>{
    let main_window = document.getElementById("main")
    main_window.classList = ["col-12"]
    main_window.style.setProperty("margin-left","0 auto")
    let sidebar = document.getElementById("sidebar")

    sidebar.parentNode.classList= [""]

    sidebar.classList = ["sidebar navbar"]

  }
  const uncollapse = () => {
    let main_window = document.getElementById("main")
    main_window.classList = ["col-10"]

    let sidebar = document.getElementById("sidebar")
    sidebar.parentNode.classList= ["col-2"]

    sidebar.classList = ["sidebar"]

  }

  React.useEffect(() => {
        // handle_sidebar(); 
  });

  return (
    <div className="App">
      
      <BrowserRouter>
      <Row style={{"--bs-gutter-x": "0", height:"100vh"}}>
      <Col className="col-2">
        <SideBar/>
        </Col>
      <Col className="col-10" id = "main" >
        <Routes>
        
        <Route path={"/"} element={<Landing></Landing>}>
        </Route>
        <Route path={"/about"} element={<About></About>}>
        </Route>
        
        <Route path={"/CAV"} element={<CAV></CAV>}>
        </Route>
          <Route path={"/CAV/build"} element={<CAVBuild/>}>
          </Route>

        <Route path={"/MULT"} element={<MULT></MULT>}></Route>

        <Route path={"/about"} element={<About></About>}>
        </Route>

        <Route path={"/DSP"} element={<DSP></DSP>}></Route>

      </Routes>
      </Col>
      </Row>
    </BrowserRouter>
    
    </div> 
  );
}


// const AnimationLayout = () => {
//   const { pathname } = useLocation();
//   return (
//     <PageLayout>
//       <motion.div
//         key={pathname}
//         initial="initial"
//         animate="in"
//         variants={pageVariants}
//         transition={pageTransition}
//       >
//         <Outlet />
//       </motion.div>
//     </PageLayout>
//   );
// };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

export default App;
